.comp-root {
  overflow-y: hidden;
  height: 100vh;
}

@media (max-width: 1024px) {
  .comp-root {
    overflow-y: auto;
  }
}

@media (max-width: 1024px) {
  .comp-root {
    height: auto;
  }
}

.index-banner {
  height: 100vh;
  background-color: #062745;
  position: relative;
}

.index-banner .v-cont {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  display: none;
  opacity: 1;
}

@media (max-width: 1024px) {
  .index-banner .v-cont {
    opacity: 0;
  }
}

.index-banner .v-cont .v {
  height: 100%;
  width: 0px;
  position: absolute;
  top: 0;
  left: 67%;
  z-index: 1;
  transition: left 6s cubic-bezier(0.23, 1, 0.32, 1);
}

.index-banner .v-cont .v .green-line, .index-banner .v-cont .v .blue-line {
  height: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  transform-origin: bottom center;
  transition: left 2s cubic-bezier(0.23, 1, 0.32, 1), right 2s cubic-bezier(0.23, 1, 0.32, 1), transform 6s cubic-bezier(0.23, 1, 0.32, 1), opacity 3s cubic-bezier(0.23, 1, 0.32, 1), background-color 0.6s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 0;
  opacity: 1;
  width: 400px;
}

.index-banner .v-cont .v .green-line img, .index-banner .v-cont .v .blue-line img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  opacity: 0;
  transition: transform 6s cubic-bezier(0.23, 1, 0.32, 1), opacity 3s cubic-bezier(0.23, 1, 0.32, 1);
}

.index-banner .v-cont .v .green-line {
  background-color: transparent;
  right: 0;
  transform: skew(30deg);
}

.index-banner .v-cont .v .green-line img {
  transform: skew(-30deg) translateX(calc(-50% - 100px));
}

.index-banner .v-cont .v .blue-line {
  background-color: transparent;
  left: 0;
  transform: skew(-30deg);
}

.index-banner .v-cont .v .blue-line img {
  transform: skew(30deg) translateX(calc(-50% + 100px));
}

.index-banner .v-cont .left-black-line, .index-banner .v-cont .right-black-line {
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 0;
  transform-origin: bottom center;
  transition: transform 6s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s cubic-bezier(0.23, 1, 0.32, 1), left 3s cubic-bezier(0.23, 1, 0.32, 1), right 3s cubic-bezier(0.23, 1, 0.32, 1);
  width: 310px;
}

.index-banner .v-cont .left-black-line {
  left: 27%;
  transform: skew(30deg) translateX(-100%);
}

.index-banner .v-cont .right-black-line {
  right: 10%;
  opacity: 0;
  transform: skew(-30deg) translateX(100%);
}

.index-banner .v-cont .bg-img {
  position: absolute;
  top: 11%;
  left: 50%;
  z-index: 0;
  opacity: 0;
  max-height: 70%;
  overflow: hidden;
  transform: translateX(-50%) translateY(30px);
  transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1), opacity 1.5s cubic-bezier(0.23, 1, 0.32, 1);
}

@media screen and (max-width: 1920px) {
  .index-banner .v-cont .bg-img {
    top: 0;
  }
}

.index-banner .pc-swiper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: block;
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper {
    display: none;
  }
}

.index-banner .pc-swiper .swiper {
  width: 100%;
  height: 100%;
}

.index-banner .pc-swiper .swiper .swiper-slide {
  position: relative;
}

.index-banner .pc-swiper .swiper .swiper-slide:nth-child(1) .slide-cont {
  left: 13%;
}

.index-banner .pc-swiper .swiper .swiper-slide:nth-child(2) .slide-cont {
  right: 9.5%;
}

.index-banner .pc-swiper .swiper .swiper-slide:nth-child(2) .slide-cont .cn, .index-banner .pc-swiper .swiper .swiper-slide:nth-child(2) .slide-cont .en {
  text-align: right;
}

.index-banner .pc-swiper .swiper .swiper-slide:nth-child(2) .slide-cont .banner-search {
  float: right;
}

.index-banner .pc-swiper .swiper .swiper-slide:nth-child(3) .slide-cont {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
}

.index-banner .pc-swiper .swiper .swiper-slide:nth-child(3) .slide-cont .cn, .index-banner .pc-swiper .swiper .swiper-slide:nth-child(3) .slide-cont .en {
  text-align: center;
}

.index-banner .pc-swiper .swiper .swiper-slide:nth-child(3) .slide-cont .banner-search {
  margin: 0 auto;
  transition-delay: 1.2s;
}

.index-banner .pc-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .cn > div {
  transform: translate3d(0, 0, 0px);
}

.index-banner .pc-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .cn > div span {
  opacity: 1;
  transform: translateY(0);
}

.index-banner .pc-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .en > div {
  transform: translate3d(0, 0, 0px);
}

.index-banner .pc-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .en > div span {
  opacity: 1;
  transform: translateY(0);
}

.index-banner .pc-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .banner-search {
  opacity: 1;
  pointer-events: all;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 1.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
  font-weight: bold;
  color: #fff;
  perspective: 300px;
  margin-bottom: 10px;
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 27.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 25.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 22.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 20.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 18px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn > div {
  overflow: hidden;
  transform: translate3d(0, 0, 20px);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.6s;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .cn span {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: .6s;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
  font-family: 'itc-md';
  color: #fff;
  text-transform: uppercase;
  line-height: 1.1;
  perspective: 200px;
  white-space: nowrap;
  margin-bottom: 50px;
  font-size: 85px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 74px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 63px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 52px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 41px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 30px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en > div {
  overflow: hidden;
  transform: translate3d(0, 0, 20px);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en > div span {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: .8s;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en > div:nth-child(2) {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 1s;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .en > div:nth-child(2) span {
  transition-delay: 1s;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search {
  opacity: 0;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 1.4s;
  width: 490px;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form {
  width: 100%;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form {
    padding-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form {
    padding-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form {
    padding-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form {
    padding-bottom: 5px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input {
  flex-grow: 1;
  color: #fff;
  background: none;
  border: none;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input {
    font-size: 14px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input::moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #fff;
  width: 40px;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form button .iconfont {
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form button .iconfont {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form button .iconfont {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form button .iconfont {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form button .iconfont {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .search-form button .iconfont {
    font-size: 20px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
  border-radius: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 20px;
  padding-left: 50px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-top: 22px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-top: 19px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-top: 16px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-top: 13px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-bottom: 22px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-left: 47px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-left: 44px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-left: 41px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-left: 38px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint {
    padding-left: 35px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint::after {
  border-radius: 5px;
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item:not(:last-child) {
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item:not(:last-child) {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item:not(:last-child) {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item:not(:last-child) {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item:not(:last-child) {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item .item-title {
  margin-bottom: 5px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item .item-title {
    margin-bottom: 5px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item .item-title {
    margin-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item .item-title {
    margin-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item .item-title {
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item .item-title {
    margin-bottom: 5px;
  }
}

.index-banner .pc-swiper .swiper .swiper-slide .slide-cont .banner-search .common-search-hint .hint-cont .hint-item .item-result:not(.name) a:not(:first-child) {
  display: none;
}

.index-banner .pc-swiper .swiper .swiper-pagination {
  width: auto;
  left: 65px;
  bottom: 65px;
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    left: 55px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    left: 45px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    left: 35px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    left: 25px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    bottom: 57px;
  }
}

@media (max-width: 1366px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    bottom: 49px;
  }
}

@media (max-width: 1024px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    bottom: 41px;
  }
}

@media (max-width: 991px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    bottom: 33px;
  }
}

@media (max-width: 767px) {
  .index-banner .pc-swiper .swiper .swiper-pagination {
    bottom: 25px;
  }
}

.index-banner .pc-swiper .swiper .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 20px;
  opacity: 1;
  margin: 0;
  border-radius: 0;
  background: none;
}

.index-banner .pc-swiper .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  width: 2px;
  height: 15px;
  border-radius: 0;
  background-color: #fff;
  opacity: 0.5;
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: center;
  margin: 0 auto;
  margin-top: 2px;
}

.index-banner .pc-swiper .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  opacity: 1;
  transform: rotate(30deg);
}

.index-banner .mob-swiper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: none;
}

@media (max-width: 1024px) {
  .index-banner .mob-swiper {
    display: block;
  }
}

.index-banner .mob-swiper .swiper {
  width: 100%;
  height: 100%;
}

.index-banner .mob-swiper .swiper .swiper-slide {
  position: relative;
}

.index-banner .mob-swiper .swiper .swiper-slide:nth-child(1) .slide-cont {
  left: 13%;
}

.index-banner .mob-swiper .swiper .swiper-slide:nth-child(2) .slide-cont {
  right: 9.5%;
}

.index-banner .mob-swiper .swiper .swiper-slide:nth-child(2) .slide-cont .cn, .index-banner .mob-swiper .swiper .swiper-slide:nth-child(2) .slide-cont .en {
  text-align: right;
}

.index-banner .mob-swiper .swiper .swiper-slide:nth-child(2) .slide-cont .banner-search {
  float: right;
}

.index-banner .mob-swiper .swiper .swiper-slide:nth-child(3) .slide-cont {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
}

.index-banner .mob-swiper .swiper .swiper-slide:nth-child(3) .slide-cont .cn, .index-banner .mob-swiper .swiper .swiper-slide:nth-child(3) .slide-cont .en {
  text-align: center;
}

.index-banner .mob-swiper .swiper .swiper-slide:nth-child(3) .slide-cont .banner-search {
  margin: 0 auto;
  transition-delay: 2s;
}

.index-banner .mob-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .cn > div {
  transform: translate3d(0, 0, 0px);
}

.index-banner .mob-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .cn > div span {
  opacity: 1;
  transform: translateY(0);
}

.index-banner .mob-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .en > div {
  transform: translate3d(0, 0, 0px);
}

.index-banner .mob-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .en > div span {
  opacity: 1;
  transform: translateY(0);
}

.index-banner .mob-swiper .swiper .swiper-slide.swiper-slide-active .slide-cont .banner-search {
  opacity: 1;
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 100% auto;
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont {
  position: absolute;
  z-index: 2;
  top: 25%;
  transition: opacity 1.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
  font-weight: bold;
  color: #fff;
  perspective: 300px;
  margin-bottom: 10px;
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    margin-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 27.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 25.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 22.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 20.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn {
    font-size: 18px;
  }
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn > div {
  overflow: hidden;
  transform: translate3d(0, 0, 20px);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.6s;
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .cn span {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: .6s;
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
  font-family: 'itc-md';
  color: #fff;
  text-transform: uppercase;
  line-height: 1.1;
  perspective: 200px;
  white-space: nowrap;
  margin-bottom: 50px;
  font-size: 85px;
}

@media (max-width: 1600px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    margin-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 74px;
  }
}

@media (max-width: 1366px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 63px;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 52px;
  }
}

@media (max-width: 991px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 41px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en {
    font-size: 30px;
  }
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en > div {
  overflow: hidden;
  transform: translate3d(0, 0, 20px);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en > div span {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: .8s;
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en > div:nth-child(2) {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 1s;
}

.index-banner .mob-swiper .swiper .swiper-slide .slide-cont .en > div:nth-child(2) span {
  transition-delay: 1s;
}

.index-banner .mob-swiper .swiper .swiper-pagination {
  bottom: 200px;
}

@media (max-width: 991px) {
  .index-banner .mob-swiper .swiper .swiper-pagination {
    bottom: 170px;
  }
}

.index-banner .mob-swiper .swiper .swiper-pagination .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 10px;
  opacity: 1;
  transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}

.index-banner .mob-swiper .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 30px;
}

.index-banner .mob-banner-search {
  position: absolute;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%);
  bottom: 120px;
  width: 490px;
  display: none;
}

@media (max-width: 767px) {
  .index-banner .mob-banner-search {
    bottom: 100px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-banner-search {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-banner-search {
    display: block;
  }
}

.index-banner .mob-banner-search .search-form {
  width: 100%;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .index-banner .mob-banner-search .search-form {
    padding-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-banner .mob-banner-search .search-form {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-banner-search .search-form {
    padding-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-banner .mob-banner-search .search-form {
    padding-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-banner-search .search-form {
    padding-bottom: 5px;
  }
}

.index-banner .mob-banner-search .search-form input {
  flex-grow: 1;
  color: #fff;
  background: none;
  border: none;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-banner .mob-banner-search .search-form input {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .mob-banner-search .search-form input {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-banner-search .search-form input {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .mob-banner-search .search-form input {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-banner-search .search-form input {
    font-size: 14px;
  }
}

.index-banner .mob-banner-search .search-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .mob-banner-search .search-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .mob-banner-search .search-form input::moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .mob-banner-search .search-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.index-banner .mob-banner-search .search-form button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #fff;
  width: 40px;
}

.index-banner .mob-banner-search .search-form button .iconfont {
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-banner .mob-banner-search .search-form button .iconfont {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .index-banner .mob-banner-search .search-form button .iconfont {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .index-banner .mob-banner-search .search-form button .iconfont {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .index-banner .mob-banner-search .search-form button .iconfont {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .index-banner .mob-banner-search .search-form button .iconfont {
    font-size: 20px;
  }
}

.index-banner .mob-banner-search .common-search-hint {
  border-radius: 5px;
}

.index-banner .mob-banner-search .common-search-hint::after {
  border-radius: 5px;
}

.index-banner .go-btn {
  position: absolute;
  z-index: 3;
  bottom: 50px;
  right: 65px;
}

@media (max-width: 1600px) {
  .index-banner .go-btn {
    bottom: 46px;
  }
}

@media (max-width: 1366px) {
  .index-banner .go-btn {
    bottom: 42px;
  }
}

@media (max-width: 1024px) {
  .index-banner .go-btn {
    bottom: 38px;
  }
}

@media (max-width: 991px) {
  .index-banner .go-btn {
    bottom: 34px;
  }
}

@media (max-width: 767px) {
  .index-banner .go-btn {
    bottom: 30px;
  }
}

@media (max-width: 1600px) {
  .index-banner .go-btn {
    right: 55px;
  }
}

@media (max-width: 1366px) {
  .index-banner .go-btn {
    right: 45px;
  }
}

@media (max-width: 1024px) {
  .index-banner .go-btn {
    right: 35px;
  }
}

@media (max-width: 991px) {
  .index-banner .go-btn {
    right: 25px;
  }
}

@media (max-width: 767px) {
  .index-banner .go-btn {
    right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .index-banner .go-btn {
    right: 50%;
    transform: translateX(50%);
  }
}

.index-common-cn-title {
  font-weight: bold;
  letter-spacing: 0px;
  color: #0ea292;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-common-cn-title {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .index-common-cn-title {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .index-common-cn-title {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .index-common-cn-title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .index-common-cn-title {
    font-size: 20px;
  }
}

.index-common-en-title {
  text-transform: uppercase;
  line-height: 1;
  font-family: 'itc-md';
  margin-top: 25px;
  margin-bottom: 30px;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .index-common-en-title {
    margin-top: 22px;
  }
}

@media (max-width: 1366px) {
  .index-common-en-title {
    margin-top: 19px;
  }
}

@media (max-width: 1024px) {
  .index-common-en-title {
    margin-top: 16px;
  }
}

@media (max-width: 991px) {
  .index-common-en-title {
    margin-top: 13px;
  }
}

@media (max-width: 767px) {
  .index-common-en-title {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-common-en-title {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .index-common-en-title {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .index-common-en-title {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index-common-en-title {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index-common-en-title {
    margin-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-common-en-title {
    font-size: 44.4px;
  }
}

@media (max-width: 1366px) {
  .index-common-en-title {
    font-size: 38.8px;
  }
}

@media (max-width: 1024px) {
  .index-common-en-title {
    font-size: 33.2px;
  }
}

@media (max-width: 991px) {
  .index-common-en-title {
    font-size: 27.6px;
  }
}

@media (max-width: 767px) {
  .index-common-en-title {
    font-size: 22px;
  }
}

.section1 {
  transition: background-color 1.4s cubic-bezier(0.23, 1, 0.32, 1);
  padding-top: 125px;
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .section1 {
    padding-top: 106px;
  }
}

@media (max-width: 1366px) {
  .section1 {
    padding-top: 87px;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-top: 68px;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-top: 49px;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .section1 {
    padding-bottom: 84px;
  }
}

@media (max-width: 1366px) {
  .section1 {
    padding-bottom: 68px;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-bottom: 52px;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-bottom: 36px;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-bottom: 20px;
  }
}

.section1.bg-blue {
  background-color: #062745;
}

.section1.bg-blue .index-common-en-title {
  color: #fff;
}

.section1 .index-common-cn-title {
  text-align: center;
}

.section1 .index-common-en-title {
  text-align: center;
  transition: color 1.4s cubic-bezier(0.23, 1, 0.32, 1);
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .section1 .index-common-en-title {
    margin-bottom: 62px;
  }
}

@media (max-width: 1366px) {
  .section1 .index-common-en-title {
    margin-bottom: 54px;
  }
}

@media (max-width: 1024px) {
  .section1 .index-common-en-title {
    margin-bottom: 46px;
  }
}

@media (max-width: 991px) {
  .section1 .index-common-en-title {
    margin-bottom: 38px;
  }
}

@media (max-width: 767px) {
  .section1 .index-common-en-title {
    margin-bottom: 30px;
  }
}

.section1 .common-flgc-swiper {
  position: relative;
}

.section1 .common-flgc-swiper .swiper-slide a img {
  max-height: 282px;
  opacity: .6;
}

.section1 .common-flgc-swiper .swiper-slide a .title span {
  padding: 0 10px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section1 .common-flgc-swiper .swiper-slide a .title span {
    font-size: 18.8px;
  }
}

@media (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-slide a .title span {
    font-size: 17.6px;
  }
}

@media (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-slide a .title span {
    font-size: 16.4px;
  }
}

@media (max-width: 991px) {
  .section1 .common-flgc-swiper .swiper-slide a .title span {
    font-size: 15.2px;
  }
}

@media (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-slide a .title span {
    font-size: 14px;
  }
}

.section1 .common-flgc-swiper .swiper-pagination {
  margin-top: 40px;
}

@media (max-width: 1600px) {
  .section1 .common-flgc-swiper .swiper-pagination {
    margin-top: 34px;
  }
}

@media (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-pagination {
    margin-top: 28px;
  }
}

@media (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-pagination {
    margin-top: 22px;
  }
}

@media (max-width: 991px) {
  .section1 .common-flgc-swiper .swiper-pagination {
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-pagination {
    margin-top: 10px;
  }
}

.section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
  border-radius: 50%;
  background-color: #eef3ff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 37%;
  width: 60px;
  height: 60px;
}

@media (max-width: 1600px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    top: 38%;
  }
}

@media (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    top: 39%;
  }
}

@media (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    top: 40%;
  }
}

@media (max-width: 991px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    top: 41%;
  }
}

@media (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    top: 42%;
  }
}

@media (max-width: 1600px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    width: 56px;
  }
}

@media (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    width: 52px;
  }
}

@media (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    width: 48px;
  }
}

@media (max-width: 991px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    width: 44px;
  }
}

@media (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    height: 56px;
  }
}

@media (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    height: 52px;
  }
}

@media (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    height: 48px;
  }
}

@media (max-width: 991px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    height: 44px;
  }
}

@media (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-button-prev, .section1 .common-flgc-swiper .swiper-button-next {
    height: 40px;
  }
}

.section1 .common-flgc-swiper .swiper-button-prev::after, .section1 .common-flgc-swiper .swiper-button-next::after {
  content: '';
}

.section1 .common-flgc-swiper .swiper-button-prev .iconfont, .section1 .common-flgc-swiper .swiper-button-next .iconfont {
  display: inline-block;
  color: #062745;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section1 .common-flgc-swiper .swiper-button-prev .iconfont, .section1 .common-flgc-swiper .swiper-button-next .iconfont {
    font-size: 19.6px;
  }
}

@media (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-button-prev .iconfont, .section1 .common-flgc-swiper .swiper-button-next .iconfont {
    font-size: 19.2px;
  }
}

@media (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-button-prev .iconfont, .section1 .common-flgc-swiper .swiper-button-next .iconfont {
    font-size: 18.8px;
  }
}

@media (max-width: 991px) {
  .section1 .common-flgc-swiper .swiper-button-prev .iconfont, .section1 .common-flgc-swiper .swiper-button-next .iconfont {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-button-prev .iconfont, .section1 .common-flgc-swiper .swiper-button-next .iconfont {
    font-size: 18px;
  }
}

.section1 .common-flgc-swiper .swiper-button-prev > div, .section1 .common-flgc-swiper .swiper-button-next > div {
  display: flex;
  overflow: hidden;
  width: 20px;
}

.section1 .common-flgc-swiper .swiper-button-prev {
  left: -175px;
}

@media screen and (max-width: 1680px) {
  .section1 .common-flgc-swiper .swiper-button-prev {
    left: -105px;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-button-prev {
    left: -85px;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-button-prev {
    left: -55px;
  }
}

@media screen and (max-width: 1680px) and (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-button-prev {
    left: 10px;
  }
}

.section1 .common-flgc-swiper .swiper-button-prev:hover .iconfont {
  color: #0ea292;
  transform: translateX(-100%);
}

.section1 .common-flgc-swiper .swiper-button-prev > div {
  justify-content: flex-start;
}

.section1 .common-flgc-swiper .swiper-button-next {
  right: -175px;
}

@media screen and (max-width: 1680px) {
  .section1 .common-flgc-swiper .swiper-button-next {
    right: -105px;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1366px) {
  .section1 .common-flgc-swiper .swiper-button-next {
    right: -85px;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1024px) {
  .section1 .common-flgc-swiper .swiper-button-next {
    right: -55px;
  }
}

@media screen and (max-width: 1680px) and (max-width: 767px) {
  .section1 .common-flgc-swiper .swiper-button-next {
    right: 10px;
  }
}

.section1 .common-flgc-swiper .swiper-button-next:hover .iconfont {
  color: #0ea292;
  transform: translateX(100%);
}

.section1 .common-flgc-swiper .swiper-button-next > div {
  justify-content: flex-end;
}

.section1 .ani-line-two {
  font-size: 12px;
}

.section2 {
  background-color: #062745;
  align-items: stretch;
  display: flex;
}

@media (max-width: 767px) {
  .section2 {
    display: block;
  }
}

.section2 .left {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  width: 50%;
}

@media (max-width: 1600px) {
  .section2 .left {
    padding-top: 104px;
  }
}

@media (max-width: 1366px) {
  .section2 .left {
    padding-top: 88px;
  }
}

@media (max-width: 1024px) {
  .section2 .left {
    padding-top: 72px;
  }
}

@media (max-width: 991px) {
  .section2 .left {
    padding-top: 56px;
  }
}

@media (max-width: 767px) {
  .section2 .left {
    padding-top: 40px;
  }
}

@media (max-width: 1600px) {
  .section2 .left {
    padding-bottom: 100px;
  }
}

@media (max-width: 1366px) {
  .section2 .left {
    padding-bottom: 80px;
  }
}

@media (max-width: 1024px) {
  .section2 .left {
    padding-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .section2 .left {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .section2 .left {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .left {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .section2 .left {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .section2 .left {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .section2 .left {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .section2 .left {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .section2 .left {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .section2 .left {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .section2 .left {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .section2 .left {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .section2 .left {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .section2 .left {
    border-right: none;
  }
}

@media (max-width: 767px) {
  .section2 .left {
    width: 100%;
  }
}

.section2 .left .cont {
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  color: #fff;
  transition: box-shadow 1.6s cubic-bezier(0.23, 1, 0.32, 1);
  padding-top: 130px;
  padding-bottom: 40px;
  padding-left: 65px;
  padding-right: 65px;
  width: 490px;
  height: 700px;
}

@media (max-width: 1600px) {
  .section2 .left .cont {
    padding-top: 116px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont {
    padding-top: 102px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont {
    padding-top: 88px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont {
    padding-top: 74px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont {
    padding-top: 60px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont {
    padding-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont {
    padding-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont {
    padding-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont {
    padding-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont {
    padding-left: 56px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont {
    padding-left: 47px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont {
    padding-left: 38px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont {
    padding-left: 29px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont {
    padding-right: 56px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont {
    padding-right: 47px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont {
    padding-right: 38px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont {
    padding-right: 29px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont {
    width: 400px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont {
    width: 320px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont {
    width: 300px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont {
    height: 571px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont {
    height: 457px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont {
    height: 428px;
  }
}

@media screen and (max-width: 340px) {
  .section2 .left .cont {
    width: 280px;
    height: 400px;
  }
}

.section2 .left .cont.add-shadow {
  box-shadow: 17px 17px 0 #0ea292;
}

@media (max-width: 1366px) {
  .section2 .left .cont.add-shadow {
    box-shadow: 12px 12px 0 #0ea292;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont.add-shadow {
    box-shadow: 8px 8px 0 #0ea292;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont.add-shadow {
    box-shadow: 5px 5px 0 #0ea292;
  }
}

.section2 .left .cont::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.section2 .left .cont .title {
  margin-left: -180px;
  margin-bottom: 115px;
}

@media (max-width: 1600px) {
  .section2 .left .cont .title {
    margin-left: -144px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont .title {
    margin-left: -108px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont .title {
    margin-left: -72px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont .title {
    margin-left: -36px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont .title {
    margin-left: 0px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont .title {
    margin-bottom: 98px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont .title {
    margin-bottom: 81px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont .title {
    margin-bottom: 64px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont .title {
    margin-bottom: 47px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont .title {
    margin-bottom: 30px;
  }
}

.section2 .left .cont .title h5 {
  line-height: 1.47;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 34px;
  padding-right: 60px;
}

@media (max-width: 1600px) {
  .section2 .left .cont .title h5 {
    font-size: 30.8px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont .title h5 {
    font-size: 27.6px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont .title h5 {
    font-size: 24.4px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont .title h5 {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont .title h5 {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont .title h5 {
    padding-right: 48px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont .title h5 {
    padding-right: 36px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont .title h5 {
    padding-right: 24px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont .title h5 {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont .title h5 {
    padding-right: 0px;
  }
}

.section2 .left .cont .title h5 a {
  transition: color 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}

.section2 .left .cont .title h5 a:hover {
  color: #0ea292;
}

.section2 .left .cont .title h6 {
  color: #0ea292;
  font-size: 18px;
  margin-top: 25px;
}

@media (max-width: 1600px) {
  .section2 .left .cont .title h6 {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont .title h6 {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont .title h6 {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont .title h6 {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont .title h6 {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .section2 .left .cont .title h6 {
    margin-top: 22px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont .title h6 {
    margin-top: 19px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont .title h6 {
    margin-top: 16px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont .title h6 {
    margin-top: 13px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont .title h6 {
    margin-top: 10px;
  }
}

.section2 .left .cont .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 2;
  font-size: 14px;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .section2 .left .cont .desc {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .section2 .left .cont .desc {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .section2 .left .cont .desc {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .section2 .left .cont .desc {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .section2 .left .cont .desc {
    margin-bottom: 20px;
  }
}

.section2 .left .cont .link {
  text-align: right;
}

.section2 .left .cont .link a {
  color: #fff;
}

.section2 .right {
  flex-shrink: 0;
  width: 50%;
}

@media (max-width: 767px) {
  .section2 .right {
    width: 100%;
  }
}

.section2 .right .title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 105px;
  padding-right: 210px;
  padding-top: 170px;
  padding-bottom: 60px;
}

@media (max-width: 1600px) {
  .section2 .right .title {
    padding-left: 90px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .title {
    padding-left: 75px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .title {
    padding-left: 60px;
  }
}

@media (max-width: 991px) {
  .section2 .right .title {
    padding-left: 45px;
  }
}

@media (max-width: 767px) {
  .section2 .right .title {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .title {
    padding-right: 174px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .title {
    padding-right: 138px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .title {
    padding-right: 102px;
  }
}

@media (max-width: 991px) {
  .section2 .right .title {
    padding-right: 66px;
  }
}

@media (max-width: 767px) {
  .section2 .right .title {
    padding-right: 30px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .title {
    padding-top: 142px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .title {
    padding-top: 114px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .title {
    padding-top: 86px;
  }
}

@media (max-width: 991px) {
  .section2 .right .title {
    padding-top: 58px;
  }
}

@media (max-width: 767px) {
  .section2 .right .title {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .title {
    padding-bottom: 51px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .title {
    padding-bottom: 42px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .title {
    padding-bottom: 33px;
  }
}

@media (max-width: 991px) {
  .section2 .right .title {
    padding-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .section2 .right .title {
    padding-bottom: 15px;
  }
}

.section2 .right .title h1 {
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section2 .right .title h1 {
    font-size: 19.6px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .title h1 {
    font-size: 19.2px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .title h1 {
    font-size: 18.8px;
  }
}

@media (max-width: 991px) {
  .section2 .right .title h1 {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .section2 .right .title h1 {
    font-size: 18px;
  }
}

.section2 .right .cont {
  padding-left: 105px;
  padding-right: 210px;
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (max-width: 1600px) {
  .section2 .right .cont {
    padding-left: 90px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont {
    padding-left: 75px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont {
    padding-left: 60px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont {
    padding-left: 45px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .cont {
    padding-right: 174px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont {
    padding-right: 138px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont {
    padding-right: 102px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont {
    padding-right: 66px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont {
    padding-right: 30px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .cont {
    padding-top: 88px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont {
    padding-top: 76px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont {
    padding-top: 64px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont {
    padding-top: 52px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont {
    padding-top: 40px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .cont {
    padding-bottom: 58px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont {
    padding-bottom: 56px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont {
    padding-bottom: 54px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont {
    padding-bottom: 52px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont {
    padding-bottom: 50px;
  }
}

.section2 .right .cont .desc {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-size: 22px;
  margin-left: 150px;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .section2 .right .cont .desc {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont .desc {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont .desc {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont .desc {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont .desc {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .cont .desc {
    margin-left: 130px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont .desc {
    margin-left: 110px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont .desc {
    margin-left: 90px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont .desc {
    margin-left: 70px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont .desc {
    margin-left: 50px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .cont .desc {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont .desc {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont .desc {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont .desc {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont .desc {
    margin-bottom: 20px;
  }
}

.section2 .right .cont .desc::after {
  content: '';
  display: block;
  background: url(../../assets/images/douhao_icon.png) no-repeat center;
  background-size: contain;
  position: absolute;
  right: 105%;
  top: -20%;
  width: 70px;
  height: 70px;
}

@media (max-width: 1600px) {
  .section2 .right .cont .desc::after {
    width: 62px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont .desc::after {
    width: 54px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont .desc::after {
    width: 46px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont .desc::after {
    width: 38px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont .desc::after {
    width: 30px;
  }
}

@media (max-width: 1600px) {
  .section2 .right .cont .desc::after {
    height: 62px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont .desc::after {
    height: 54px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont .desc::after {
    height: 46px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont .desc::after {
    height: 38px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont .desc::after {
    height: 30px;
  }
}

.section2 .right .cont .common-latest-news-list {
  font-size: 18px;
}

@media (max-width: 1600px) {
  .section2 .right .cont .common-latest-news-list {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont .common-latest-news-list {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont .common-latest-news-list {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont .common-latest-news-list {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont .common-latest-news-list {
    font-size: 16px;
  }
}

.section2 .right .cont .common-latest-news-list ul li a {
  color: #fff;
}

.section2 .right .cont .common-latest-news-list ul li a span::after {
  background-color: rgba(255, 255, 255, 0.3);
}

.section2 .right .cont .common-latest-news-list ul li a:hover {
  color: #0ea292;
}

.section2 .right .cont .more {
  text-align: right;
  margin-top: 40px;
}

@media (max-width: 1600px) {
  .section2 .right .cont .more {
    margin-top: 34px;
  }
}

@media (max-width: 1366px) {
  .section2 .right .cont .more {
    margin-top: 28px;
  }
}

@media (max-width: 1024px) {
  .section2 .right .cont .more {
    margin-top: 22px;
  }
}

@media (max-width: 991px) {
  .section2 .right .cont .more {
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .section2 .right .cont .more {
    margin-top: 10px;
  }
}

.section2 .right .cont .more .ani-line-two {
  color: #fff;
}

.section3 {
  padding-top: 105px;
  padding-bottom: 130px;
}

@media (max-width: 1600px) {
  .section3 {
    padding-top: 94px;
  }
}

@media (max-width: 1366px) {
  .section3 {
    padding-top: 83px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-top: 72px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-top: 61px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-top: 50px;
  }
}

@media (max-width: 1600px) {
  .section3 {
    padding-bottom: 114px;
  }
}

@media (max-width: 1366px) {
  .section3 {
    padding-bottom: 98px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-bottom: 82px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-bottom: 66px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-bottom: 50px;
  }
}

.section3 .container {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section3 .container {
    display: block;
  }
}

.section3 .container .section3-cont {
  flex-shrink: 0;
  width: 42.7%;
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont {
    width: 46%;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont {
    width: 100%;
  }
}

.section3 .container .section3-cont:first-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section3 .container .section3-cont:first-child {
    margin-bottom: 50px;
  }
}

.section3 .container .section3-cont .sec3-title {
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .sec3-title {
    margin-bottom: 23px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .sec3-title {
    margin-bottom: 21px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .sec3-title {
    margin-bottom: 19px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .sec3-title {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .sec3-title {
    margin-bottom: 15px;
  }
}

.section3 .container .section3-cont .sec3-title .index-common-en-title {
  margin-top: 0;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-top: 9px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-top: 8px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-top: 7px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-top: 6px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-top: 5px;
  }
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .sec3-title .index-common-en-title {
    margin-bottom: 10px;
  }
}

.section3 .container .section3-cont .sec3-title .more {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.section3 .container .section3-cont .sec3-title .more::before {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background-color: #cccccc;
}

.section3 .container .section3-cont .sec3-title .more a {
  margin-left: 10px;
  color: #000;
  letter-spacing: 0px;
  font-weight: bold;
  transition: color 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 14px;
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .sec3-title .more a {
    font-size: 13.6px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .sec3-title .more a {
    font-size: 13.2px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .sec3-title .more a {
    font-size: 12.8px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .sec3-title .more a {
    font-size: 12.4px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .sec3-title .more a {
    font-size: 12px;
  }
}

.section3 .container .section3-cont .sec3-title .more a:hover {
  color: #0ea292;
}

.section3 .container .section3-cont .list ul li {
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .list ul li {
    margin-bottom: 22px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .list ul li {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .list ul li {
    margin-bottom: 26px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .list ul li {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .list ul li {
    margin-bottom: 30px;
  }
}

.section3 .container .section3-cont .list ul li .date {
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  margin-bottom: 12px;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .list ul li .date {
    margin-bottom: 11.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .list ul li .date {
    margin-bottom: 10.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .list ul li .date {
    margin-bottom: 9.6px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .list ul li .date {
    margin-bottom: 8.8px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .list ul li .date {
    margin-bottom: 8px;
  }
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .list ul li .date {
    font-size: 13.6px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .list ul li .date {
    font-size: 13.2px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .list ul li .date {
    font-size: 12.8px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .list ul li .date {
    font-size: 12.4px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .list ul li .date {
    font-size: 12px;
  }
}

.section3 .container .section3-cont .list ul li .date span {
  letter-spacing: 0;
  display: inline-block;
  border-radius: 50px;
  background-color: #eaedf0;
  color: #062745;
  margin-left: 10px;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-left: 14px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-left: 13px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-left: 12px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-left: 11px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-right: 14px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-right: 13px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-right: 12px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-right: 11px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .list ul li .date span {
    padding-right: 10px;
  }
}

.section3 .container .section3-cont .list ul li a:hover .title {
  color: #0ea292;
}

.section3 .container .section3-cont .list ul li .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #000;
  transition: color 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 22px;
  margin-bottom: 12px;
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .list ul li .title {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .list ul li .title {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .list ul li .title {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .list ul li .title {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .list ul li .title {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .section3 .container .section3-cont .list ul li .title {
    margin-bottom: 11.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .container .section3-cont .list ul li .title {
    margin-bottom: 10.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .container .section3-cont .list ul li .title {
    margin-bottom: 9.6px;
  }
}

@media (max-width: 991px) {
  .section3 .container .section3-cont .list ul li .title {
    margin-bottom: 8.8px;
  }
}

@media (max-width: 767px) {
  .section3 .container .section3-cont .list ul li .title {
    margin-bottom: 8px;
  }
}

.section3 .container .section3-cont .list ul li .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.section4 {
  align-items: stretch;
  display: flex;
}

@media (max-width: 767px) {
  .section4 {
    display: block;
  }
}

.section4 .left {
  position: relative;
  flex-shrink: 0;
  width: 50%;
}

@media (max-width: 767px) {
  .section4 .left {
    width: 100%;
  }
}

.section4 .left .logo-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 366px;
}

@media (max-width: 1366px) {
  .section4 .left .logo-img {
    width: 260px;
  }
}

@media (max-width: 1024px) {
  .section4 .left .logo-img {
    width: 200px;
  }
}

@media (max-width: 991px) {
  .section4 .left .logo-img {
    width: 160px;
  }
}

.section4 .left .logo-img .ani-z, .section4 .left .logo-img .ani-y {
  display: block;
  transition-delay: .2s;
}

.section4 .left .logo-img img {
  display: block;
  width: 100%;
  height: auto;
}

.section4 .right {
  text-align: center;
  flex-shrink: 0;
  background-color: #eaedf0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

@media (max-width: 767px) {
  .section4 .right {
    width: 100%;
  }
}

.section4 .right .cont {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1600px) {
  .section4 .right .cont {
    padding-top: 46px;
  }
}

@media (max-width: 1366px) {
  .section4 .right .cont {
    padding-top: 42px;
  }
}

@media (max-width: 1024px) {
  .section4 .right .cont {
    padding-top: 38px;
  }
}

@media (max-width: 991px) {
  .section4 .right .cont {
    padding-top: 34px;
  }
}

@media (max-width: 767px) {
  .section4 .right .cont {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .section4 .right .cont {
    padding-bottom: 46px;
  }
}

@media (max-width: 1366px) {
  .section4 .right .cont {
    padding-bottom: 42px;
  }
}

@media (max-width: 1024px) {
  .section4 .right .cont {
    padding-bottom: 38px;
  }
}

@media (max-width: 991px) {
  .section4 .right .cont {
    padding-bottom: 34px;
  }
}

@media (max-width: 767px) {
  .section4 .right .cont {
    padding-bottom: 30px;
  }
}

@media (max-width: 1600px) {
  .section4 .right .cont {
    padding-left: 22px;
  }
}

@media (max-width: 1366px) {
  .section4 .right .cont {
    padding-left: 24px;
  }
}

@media (max-width: 1024px) {
  .section4 .right .cont {
    padding-left: 26px;
  }
}

@media (max-width: 991px) {
  .section4 .right .cont {
    padding-left: 28px;
  }
}

@media (max-width: 767px) {
  .section4 .right .cont {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .section4 .right .cont {
    padding-right: 22px;
  }
}

@media (max-width: 1366px) {
  .section4 .right .cont {
    padding-right: 24px;
  }
}

@media (max-width: 1024px) {
  .section4 .right .cont {
    padding-right: 26px;
  }
}

@media (max-width: 991px) {
  .section4 .right .cont {
    padding-right: 28px;
  }
}

@media (max-width: 767px) {
  .section4 .right .cont {
    padding-right: 30px;
  }
}

.section4 .right .cont h2 .ani-z, .section4 .right .cont h2 .ani-y {
  transition-delay: .1s;
}

.section4 .right .cont h3 {
  color: #000;
  letter-spacing: 0px;
  font-size: 26px;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .section4 .right .cont h3 {
    font-size: 24.4px;
  }
}

@media (max-width: 1366px) {
  .section4 .right .cont h3 {
    font-size: 22.8px;
  }
}

@media (max-width: 1024px) {
  .section4 .right .cont h3 {
    font-size: 21.2px;
  }
}

@media (max-width: 991px) {
  .section4 .right .cont h3 {
    font-size: 19.6px;
  }
}

@media (max-width: 767px) {
  .section4 .right .cont h3 {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .section4 .right .cont h3 {
    margin-bottom: 58px;
  }
}

@media (max-width: 1366px) {
  .section4 .right .cont h3 {
    margin-bottom: 46px;
  }
}

@media (max-width: 1024px) {
  .section4 .right .cont h3 {
    margin-bottom: 34px;
  }
}

@media (max-width: 991px) {
  .section4 .right .cont h3 {
    margin-bottom: 22px;
  }
}

@media (max-width: 767px) {
  .section4 .right .cont h3 {
    margin-bottom: 10px;
  }
}

.section4 .right .cont h3 .ani-z, .section4 .right .cont h3 .ani-y {
  transition-delay: .2s;
}

.section4 .right .cont .desc {
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
}

.section4 .right .cont .desc .ani-y {
  transition-delay: .3s;
}

.section4 .right .cont .link {
  margin-top: 60px;
}

@media (max-width: 1600px) {
  .section4 .right .cont .link {
    margin-top: 50px;
  }
}

@media (max-width: 1366px) {
  .section4 .right .cont .link {
    margin-top: 40px;
  }
}

@media (max-width: 1024px) {
  .section4 .right .cont .link {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .section4 .right .cont .link {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .section4 .right .cont .link {
    margin-top: 10px;
  }
}

.section5 {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 160px;
  padding-bottom: 80px;
}

@media (max-width: 1600px) {
  .section5 {
    padding-left: 102px;
  }
}

@media (max-width: 1366px) {
  .section5 {
    padding-left: 84px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    padding-left: 66px;
  }
}

@media (max-width: 991px) {
  .section5 {
    padding-left: 48px;
  }
}

@media (max-width: 767px) {
  .section5 {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .section5 {
    padding-right: 102px;
  }
}

@media (max-width: 1366px) {
  .section5 {
    padding-right: 84px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    padding-right: 66px;
  }
}

@media (max-width: 991px) {
  .section5 {
    padding-right: 48px;
  }
}

@media (max-width: 767px) {
  .section5 {
    padding-right: 30px;
  }
}

@media (max-width: 1600px) {
  .section5 {
    padding-top: 138px;
  }
}

@media (max-width: 1366px) {
  .section5 {
    padding-top: 116px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    padding-top: 94px;
  }
}

@media (max-width: 991px) {
  .section5 {
    padding-top: 72px;
  }
}

@media (max-width: 767px) {
  .section5 {
    padding-top: 50px;
  }
}

@media (max-width: 1600px) {
  .section5 {
    padding-bottom: 64px;
  }
}

@media (max-width: 1366px) {
  .section5 {
    padding-bottom: 48px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    padding-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .section5 {
    padding-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .section5 {
    padding-bottom: 0px;
  }
}

.section5 ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.section5 ul li {
  flex-shrink: 0;
  height: 140px;
  width: 20%;
  margin-bottom: 80px;
}

@media (max-width: 1600px) {
  .section5 ul li {
    height: 128px;
  }
}

@media (max-width: 1366px) {
  .section5 ul li {
    height: 116px;
  }
}

@media (max-width: 1024px) {
  .section5 ul li {
    height: 104px;
  }
}

@media (max-width: 991px) {
  .section5 ul li {
    height: 92px;
  }
}

@media (max-width: 767px) {
  .section5 ul li {
    height: 80px;
  }
}

@media (max-width: 991px) {
  .section5 ul li {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .section5 ul li {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .section5 ul li {
    margin-bottom: 74px;
  }
}

@media (max-width: 1366px) {
  .section5 ul li {
    margin-bottom: 68px;
  }
}

@media (max-width: 1024px) {
  .section5 ul li {
    margin-bottom: 62px;
  }
}

@media (max-width: 991px) {
  .section5 ul li {
    margin-bottom: 56px;
  }
}

@media (max-width: 767px) {
  .section5 ul li {
    margin-bottom: 50px;
  }
}

.section5 ul li a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section5 ul li img {
  display: block;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: 0 auto;
  width: auto;
  max-width: 80%;
  max-height: 120px;
}

@media (max-width: 767px) {
  .section5 ul li img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .section5 ul li img {
    max-height: 100px;
  }
}

.section5 ul li img:hover {
  transform: translateY(-10%);
}

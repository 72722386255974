@import "../../assets/styles/utils";

.comp-root{
  @include res(overflow-y,hidden,(md:auto));
  @include res(height,100vh,(md:auto));
}
.index-banner{
  // display: none;
  height: 100vh;
  background-color: $color-blue-dark;
  position: relative;
  .v-cont{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    display: none;
    @include res(opacity,1,(md:0));
    .v{
      height: 100%;
      width: 0px;
      position: absolute;
      top: 0;
      left: 67%;
      z-index: 1;
      transition: left 6s $anime-bezier;
      .green-line,.blue-line{
        height: 100%;
        position: absolute;
        bottom: 0;
        overflow: hidden;
        transform-origin: bottom center;
        transition:
          left 2s $anime-bezier,
          right 2s $anime-bezier, 
          transform 6s $anime-bezier, 
          opacity 3s $anime-bezier, 
          background-color .6s $anime-bezier,
          box-shadow .6s $anime-bezier;
        z-index: 0;
        opacity: 1;
        @include res(width, 400px);
        img{
          position: absolute;
          // top: -25%;
          top: 0;
          left: 50%;
          // height: 150%;
          height: 100%;
          opacity: 0;
          transition: transform 6s $anime-bezier, opacity 3s $anime-bezier;
        }
      }
      .green-line{
        // background-color: $color-green;
        background-color: transparent;
        right: 0;
        transform: skew(30deg);
        img{
          transform: skew(-30deg) translateX(calc(-50% - 100px));
        }
      }
      .blue-line{
        // background-color: $color-blue;
        background-color: transparent;
        left: 0;
        transform: skew(-30deg) ;
        img{
          transform: skew(30deg) translateX(calc(-50% + 100px));
        }
      }
    }
    .left-black-line,.right-black-line{
      height: 100%;
      background-image: linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,0));
      position: absolute;
      bottom: 0;
      transform-origin: bottom center;
      transition: transform 6s $anime-bezier, opacity 1s $anime-bezier, left 3s $anime-bezier, right 3s $anime-bezier;
      @include res(width, 310px);
    }
    .left-black-line{
      left: 27%;
      transform: skew(30deg) translateX(-100%);
    }
    .right-black-line{
      right: 10%;
      opacity: 0;
      transform: skew(-30deg) translateX(100%);
    }
    .bg-img{
      position: absolute;
      top: 11%;
      left: 50%;
      z-index: 0;
      opacity: 0;
      max-height: 70%;
      overflow: hidden;
      transform: translateX(-50%) translateY(30px);
      transition: transform 3s $anime-bezier, opacity 1.5s $anime-bezier;
      @media screen and (max-width:1920px) {
        top: 0;
      }
    }
  }
  .pc-swiper{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    @include res(display,block,(md:none));
    .swiper{
      width: 100%;
      height: 100%;
      .swiper-slide{
        position: relative;
        &:nth-child(1){
          .slide-cont{
            @include res(left,13%)
          }
        }
        &:nth-child(2){
          .slide-cont{
            .cn,.en{
              text-align: right;
            }
            .banner-search{
              float: right;
            }
            @include res(right,9.5%)
          }
        }
        &:nth-child(3){
          .slide-cont{
            transform: translate3d(-50%, -50%, 0);
            @include res(left,50%);
            .cn,.en{
              text-align: center;
            }
            .banner-search{
              margin: 0 auto;
              transition-delay: 1.2s;
            }
          }
        }
        &.swiper-slide-active{
          .slide-cont{
            .cn>div{
              transform: translate3d(0,0,0px);
              span{
                opacity: 1;
                transform: translateY(0);
              }
            }
            .en>div{
              transform: translate3d(0,0,0px);
              span{
                opacity: 1;
                transform: translateY(0);
              }
            }
            .banner-search{
              opacity: 1;
              pointer-events: all;
            }
          }
        }
        .slide-cont{
          position: absolute;
          z-index: 2;
          top: 50%;
          transform: translateY(-50%);
          transition: opacity 1.6s $anime-bezier;
          .cn{
            font-weight: bold;
            color: #fff;
            perspective: 300px;
            @include res(margin-bottom, 10px, 5 / 10);
            @include res(font-size, 30px, 18 / 30);
            >div{
              overflow: hidden;
              transform: translate3d(0,0,20px);
              transition: all 1s $anime-bezier .6s;
            }
            span{
              display: inline-block;
              transform: translateY(100%);
              opacity: 0;
              transition: all 1s $anime-bezier;
              transition-delay: .6s;
            }
          }
          .en{
            font-family: 'itc-md';
            color: #fff;
            text-transform: uppercase;
            line-height: 1.1;
            perspective: 200px;
            white-space: nowrap;
            @include res(margin-bottom, 50px, 20 / 50);
            @include res(font-size, 85px, 30 / 85);
            >div{
              overflow: hidden;
              transform: translate3d(0,0,20px);
              transition: all 1s $anime-bezier .8s;
              span{
                display: inline-block;
                transform: translateY(100%);
                opacity: 0;
                transition: all 1s $anime-bezier;
                transition-delay: .8s;
              }
              &:nth-child(2){
                transition: all 1s $anime-bezier 1s;
                span{
                  transition-delay: 1s;
                }
              }
            }
          }
          .banner-search{
            opacity: 0;
            transition: all 1s $anime-bezier;
            transition-delay: 1.4s;
            @include res(width,490px);
            .search-form{
              width: 100%;
              border-bottom: 1px solid #fff;
              display: flex;
              align-items: stretch;
              justify-content: space-between;
              @include res(padding-bottom,10px, 5 / 10);
              input{
                flex-grow: 1;
                color: #fff;
                background: none;
                border: none;
                @include res(font-size, 18px, 14 / 18);
                &::-webkit-input-placeholder{
                  color: rgba(255,255,255,.5);
                }
                &:-moz-placeholder{
                  color: rgba(255,255,255,.5);
                }
                &::moz-placeholder{
                  color: rgba(255,255,255,.5);
                }
                &:-ms-input-placeholder{
                  color: rgba(255,255,255,.5);
                }
              }
              button{
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background: transparent;
                border: none;
                color: #fff;
                @include res(width,40px);
                .iconfont{
                  @include res(font-size, 30px, 20 / 30);
                }
              }
            }
            .common-search-hint{
              border-radius: 5px;
              @include res(padding-top,25px, 10 / 25);
              @include res(padding-bottom,25px, 10 / 25);
              @include res(padding-right,20px, 10 / 20);
              @include res(padding-left,50px, 35 / 50);
              &::after{
                border-radius: 5px;
              }
              .hint-cont{
                .hint-item{
                  &:not(:last-child){
                    @include res(margin-bottom, 15px, 10 / 15);
                  }
                  .item-title{
                    @include res(margin-bottom,5px, 5 / 5);
                  }
                  .item-result{
                    &:not(.name){
                      a{
                        &:not(:first-child){
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .swiper-pagination{
        width: auto;
        @include res(left, 65px, 15 / 65);
        @include res(bottom, 65px, 25 / 65);
        .swiper-pagination-bullet{
          width: 12px;
          height: 20px;
          opacity: 1;
          margin: 0;
          border-radius:0;
          background: none;
          &::after{
            content: '';
            display: block;
            width: 2px;
            height: 15px;
            border-radius: 0;
            background-color: #fff;
            opacity: 0.5;
            transition: all .35s $anime-bezier;
            transform-origin: center;
            margin: 0 auto;
            margin-top: 2px;
          }
          &.swiper-pagination-bullet-active::after{
            opacity: 1;
            transform: rotate(30deg);
          }
        }
      }
    }
  }
  .mob-swiper{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    @include res(display,none,(md:block));
    .swiper{
      width: 100%;
      height: 100%;
      .swiper-slide{
        position: relative;
        &:nth-child(1){
          .slide-cont{
            @include res(left,13%)
          }
        }
        &:nth-child(2){
          .slide-cont{
            .cn,.en{
              text-align: right;
            }
            .banner-search{
              float: right;
            }
            @include res(right,9.5%)
          }
        }
        &:nth-child(3){
          .slide-cont{
            transform: translate3d(-50%, -50%, 0);
            @include res(left,50%);
            .cn,.en{
              text-align: center;
            }
            .banner-search{
              margin: 0 auto;
              transition-delay: 2s;
            }
          }
        }
        &.swiper-slide-active{
          .slide-cont{
            .cn>div{
              transform: translate3d(0,0,0px);
              span{
                opacity: 1;
                transform: translateY(0);
              }
            }
            .en>div{
              transform: translate3d(0,0,0px);
              span{
                opacity: 1;
                transform: translateY(0);
              }
            }
            .banner-search{
              opacity: 1;
            }
          }
        }
        .slide-img{
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: 100% auto;
        }
        .slide-cont{
          position: absolute;
          z-index: 2;
          top: 25%;
          transition: opacity 1.6s $anime-bezier;
          .cn{
            font-weight: bold;
            color: #fff;
            perspective: 300px;
            @include res(margin-bottom, 10px, 5 / 10);
            @include res(font-size, 30px, 18 / 30);
            >div{
              overflow: hidden;
              transform: translate3d(0,0,20px);
              transition: all 1s $anime-bezier .6s;
            }
            span{
              display: inline-block;
              transform: translateY(100%);
              opacity: 0;
              transition: all 1s $anime-bezier;
              transition-delay: .6s;
            }
          }
          .en{
            font-family: 'itc-md';
            color: #fff;
            text-transform: uppercase;
            line-height: 1.1;
            perspective: 200px;
            white-space: nowrap;
            @include res(margin-bottom, 50px, 20 / 50);
            @include res(font-size, 85px, 30 / 85);
            >div{
              overflow: hidden;
              transform: translate3d(0,0,20px);
              transition: all 1s $anime-bezier .8s;
              span{
                display: inline-block;
                transform: translateY(100%);
                opacity: 0;
                transition: all 1s $anime-bezier;
                transition-delay: .8s;
              }
              &:nth-child(2){
                transition: all 1s $anime-bezier 1s;
                span{
                  transition-delay: 1s;
                }
              }
            }
          }
        }
      }
      .swiper-pagination{
        @include res(bottom, 200px,(sm:170px));
        .swiper-pagination-bullet{
          width: 5px;
          height: 5px;
          background-color: #fff;
          border-radius: 10px;
          opacity: 1;
          transition: all .35s $anime-bezier;
          &.swiper-pagination-bullet-active{
            width: 30px;
          }
        }
      }
    }
  }
  .mob-banner-search{
    position: absolute;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    @include res(bottom, 120px,(xs:100px));
    @include res(width,490px,(xs:80%));
    @include res(display,none,(md:block));
    .search-form{
      width: 100%;
      border-bottom: 1px solid #fff;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      @include res(padding-bottom,10px, 5 / 10);
      input{
        flex-grow: 1;
        color: #fff;
        background: none;
        border: none;
        @include res(font-size, 18px, 14 / 18);
        &::-webkit-input-placeholder{
          color: rgba(255,255,255,.5);
        }
        &:-moz-placeholder{
          color: rgba(255,255,255,.5);
        }
        &::moz-placeholder{
          color: rgba(255,255,255,.5);
        }
        &:-ms-input-placeholder{
          color: rgba(255,255,255,.5);
        }
      }
      button{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: transparent;
        border: none;
        color: #fff;
        @include res(width,40px);
        .iconfont{
          @include res(font-size, 30px, 20 / 30);
        }
      }
    }
    .common-search-hint{
      border-radius: 5px;
      &::after{
        border-radius: 5px;
      }
    }
  }
  .go-btn{
    position: absolute;
    z-index: 3;
    @include res(bottom,50px, 30 / 50);
    @include res(right,65px, 15 / 65);
    @media screen and (max-width:1024px) {
      right: 50%;
      transform: translateX(50%);
    }
  }
}

.index-common-cn-title{
  font-weight: bold;
  letter-spacing: 0px;
  color: $color-green;
  @include res(font-size,20px, 20 / 20);
}
.index-common-en-title{
  text-transform: uppercase;
  line-height: 1;
  font-family: 'itc-md';
  @include res(margin-top,25px, 10 / 25);
  @include res(margin-bottom,30px, 15 / 30);
  @include res(font-size,50px, 22 / 50);
}

.section1{
  transition: background-color 1.4s $anime-bezier;
  @include res(padding-top,125px, 30 / 125);
  @include res(padding-bottom,100px, 20 / 100);
  &.bg-blue{
    background-color: $color-blue-dark;
    .index-common-en-title{
      color: #fff;
    }
  }
  .index-common-cn-title{
    text-align: center;
  }
  .index-common-en-title{
    text-align: center;
    transition: color 1.4s $anime-bezier;
    @include res(margin-bottom,70px, 30 / 70);
  }
  .common-flgc-swiper{
    position: relative;
    .swiper-slide{
      a{
        img{
          max-height: 282px;
          opacity: .6;
        }
        .title{
          span{
            padding: 0 10px;
            @include res(font-size,20px, 14 / 20);
          }
        }
      }
    }
    .swiper-pagination{
      @include res(margin-top,40px, 10 / 40);
    }
    .swiper-button-prev,.swiper-button-next{
      border-radius: 50%;
      background-color: #eef3ff;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(top,37%, 42 / 37);
      @include res(width,60px, 40 / 60);
      @include res(height,60px, 40 / 60);
      &::after{
        content: '';
      }
      .iconfont{
        display: inline-block;
        color: $color-blue-dark;
        transition: all .7s $anime-bezier;
        @include res(font-size, 20px, 18 / 20);
      }
      >div{
        display: flex;
        overflow: hidden;
        @include res(width, 20px);
      }
    }
    .swiper-button-prev{
      @include res(left, -175px);
      @media screen and (max-width:1680px){
        @include res(left, -105px,(mmd:-85px,md:-55px,xs:10px));
      }
      &:hover{
        .iconfont{
          color: $color-green;
          transform: translateX(-100%);
        }
      }
      >div{
        justify-content: flex-start;
      }
    }
    .swiper-button-next{
      @include res(right, -175px);
      @media screen and (max-width:1680px){
        @include res(right, -105px,(mmd:-85px,md:-55px,xs:10px));
      }
      &:hover{
        .iconfont{
          color: $color-green;
          transform: translateX(100%);
        }
      }
      >div{
        justify-content: flex-end;
      }
    }
  } 
  .ani-line-two{
    font-size: 12px;
  }
}

.section2{
  background-color: $color-blue-dark;
  align-items: stretch;
  @include res(display,flex,(xs:block));
  .left{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    @include res(padding-top,120px, 40 / 120);
    @include res(padding-bottom,120px, 20 / 120);
    @include res(padding-left,20px, 10 / 20);
    @include res(padding-right,20px, 10 / 20);
    @include res(border-right,1px solid rgba(255,255,255,.2),(xs:none));
    @include res(width, 50%,(xs:100%));
    .cont{
      background-position: center;
      background-size: cover;
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      color: #fff;
      transition: box-shadow 1.6s $anime-bezier;
      @include res(padding-top,130px, 60 / 130);
      @include res(padding-bottom,40px, 20 / 40);
      @include res(padding-left,65px, 20 / 65);
      @include res(padding-right,65px, 20 / 65);
      @include res(width,490px,(lg:400px,md:320px,sm:300px));
      @include res(height,700px,(lg:571px,md:457px,sm:428px));
      @media screen and (max-width: 340px) {
        width: 280px;
        height: 400px;
      }
      &.add-shadow{
        @include res(box-shadow,17px 17px 0 $color-green,(mmd:12px 12px 0 $color-green,sm:8px 8px 0 $color-green,xs:5px 5px 0 $color-green));
      }
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      .title{
        @include res(margin-left, -180px, 0 / -180);
        @include res(margin-bottom,115px, 30 / 115);
        h5{
          line-height: 1.47;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          @include res(font-size,34px, 18 / 34);
          @include res(padding-right, 60px, 0 / 60);
          a{
            transition: color .7s $anime-bezier;
            &:hover{
              color: $color-green;
            }
          }
        }
        h6{
          color: $color-green;
          @include res(font-size, 18px, 14 / 18);
          @include res(margin-top, 25px, 10 / 25);
        }
      }
      .desc{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        @include res(line-height, 2,);
        @include res(font-size, 14px);
        @include res(margin-bottom, 70px, 20 / 70);
      }
      .link{
        text-align: right;
        a{
          color: #fff;
        }
      }
    }
  }
  .right{
    flex-shrink: 0;
    @include res(width, 50%,(xs:100%));
    .title{
      border-bottom: 1px solid rgba(255,255,255,.2);
      @include res(padding-left,105px, 30 / 105);
      @include res(padding-right,210px, 30 / 210);
      @include res(padding-top,170px, 30 / 170);
      @include res(padding-bottom,60px, 15 / 60);
      h1{
        font-weight: bold;
        color: #fff;
        letter-spacing: 0px;
        @include res(font-size, 20px, 18 / 20);
      }
    }
    .cont{
      @include res(padding-left,105px, 30 / 105);
      @include res(padding-right,210px, 30 / 210);
      @include res(padding-top,100px, 40 / 100);
      @include res(padding-bottom,60px, 50 / 60);
      .desc{
        position: relative;
        color: rgba(255,255,255,.6);
        @include res(font-size, 22px, 16 / 22);
        @include res(margin-left,150px, 50 / 150);
        @include res(margin-bottom, 70px, 20 / 70);
        &::after{
          content: '';
          display: block;
          background: url(../../assets/images/douhao_icon.png) no-repeat center;
          background-size: contain;
          position: absolute;
          right: 105%;
          top: -20%;
          @include res(width, 70px, 30 / 70);
          @include res(height, 70px, 30 / 70);
        }
      }
      .common-latest-news-list{
        @include res(font-size, 18px, 16 / 18);
        ul li a{
          color: #fff;
          span::after{
            background-color: rgba(255,255,255,.3);
          }
          &:hover{
            color: $color-green;
          }
        }
      }
      .more{
        text-align: right;
        @include res(margin-top,40px, 10 / 40);
        .ani-line-two{
          color: #fff;
        }
      }
    }
  }
}

.section3{
  @include res(padding-top,105px, 50 / 105);
  @include res(padding-bottom,130px, 50 / 130);
  .container{
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    .section3-cont{
      flex-shrink: 0;
      @include res(width,42.7%,(md:46%,xs:100%));
      &:first-child{
        @include res(margin-bottom,0,(xs:50px));
      }
      .sec3-title{
        @include res(margin-bottom,25px, 15 / 25);
        .index-common-en-title{
          margin-top: 0;
          @include res(margin-top, 10px, 5 / 10);
          @include res(margin-bottom,20px, 10 / 20);
        }
        .more{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &::before{
            content: '';
            display: block;
            flex-grow: 1;
            height: 1px;
            background-color: #cccccc;
          }
          a{
            margin-left: 10px;
            color: #000;
            letter-spacing: 0px;
            font-weight: bold;
            transition: color .7s $anime-bezier;
            @include res(font-size, 14px, 12 / 14);
            &:hover{
              color: $color-green;
            }
          }
        }
      }
      .list{
        ul{
          li{
            @include res(margin-bottom, 20px, 30 / 20);
            .date{
              display: flex;
              align-items: center;
              letter-spacing: 2px;
              @include res(margin-bottom, 12px, 8 / 12);
              @include res(font-size, 14px, 12 / 14);
              span{
                letter-spacing: 0;
                display: inline-block;
                border-radius: 50px;
                background-color: #eaedf0;
                color: $color-blue-dark;
                margin-left: 10px;
                @include res(line-height, 30px);
                @include res(padding-left,15px, 10 / 15);
                @include res(padding-right,15px, 10 / 15);
              }
            }
            a:hover{
              .title{
                color: $color-green;
              }
            }
            .title{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              color: #000;
              transition: color .7s $anime-bezier;
              @include res(font-size, 22px, 18 / 22);
              @include res(margin-bottom,12px, 8 / 12);
            }
            .desc{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

.section4{
  align-items: stretch;
  @include res(display,flex,(xs:block));
  .left{
    position: relative;
    flex-shrink: 0;
    @include res(width,50%,(xs:100%));
    .logo-img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      @include res(width, 366px,(mmd:260px,md:200px,sm:160px));
      .ani-z,.ani-y{
        display: block;
        transition-delay: .2s;
      }
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .right{
    text-align: center;
    flex-shrink: 0;
    background-color: $color-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    @include res(width,50%,(xs:100%));
    .cont{
      @include res(padding-top, 50px, 30 / 50);
      @include res(padding-bottom, 50px, 30 / 50);
      @include res(padding-left, 20px, 30 / 20);
      @include res(padding-right, 20px, 30 / 20);
      h2{
        .ani-z,.ani-y{
          transition-delay: .1s;
        }
      }
      h3{
        color: #000;
        letter-spacing: 0px;
        @include res(font-size,26px, 18 / 26);
        @include res(margin-bottom,70px, 10 / 70);
        .ani-z,.ani-y{
          transition-delay: .2s;
        }
      }
      .desc{
        max-width: 500px;
        margin: 0 auto;
        overflow: hidden;
        .ani-y{
          transition-delay: .3s;
        }
      }
      .link{
        @include res(margin-top,60px, 10 / 60);
      }
    }
  }
}

.section5{
  @include res(padding-left,120px, 30 / 120);
  @include res(padding-right,120px, 30 / 120);
  @include res(padding-top,160px, 50 / 160);
  @include res(padding-bottom,80px, 0 / 80);
  ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    li{
      flex-shrink: 0;
      @include res(height,140px,80 / 140);
      @include res(width, 20%,(sm:33.33%,xs:100%));
      @include res(margin-bottom,80px, 50 / 80);
      a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img{
        display: block;
        transition: all .7s $anime-bezier;
        margin: 0 auto;
        width: auto;
        // @include res(width,80%,(xs:auto));
        @include res(max-width,80%,(xs:100%));
        @include res(max-height,120px,(xs:100px));
        &:hover{
          transform: translateY(-10%);
        }
      }
    }
  }
}